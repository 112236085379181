(function () {
  'use strict';

  angular
      .module('neo.home.championships.championship.assignTeams')
      .config(config);

  function config($stateProvider) {
    $stateProvider
        .state('home.championships.championship.assignTeams', {
          url: '/assign-teams',
          templateUrl: 'home/championships/championship/assign-teams/assign-teams.tpl.html',
          controller: 'AssignTeamsCtrl',
          controllerAs: 'vm',
          resolve: {
            teams: function (InscribedTeams, $stateParams, championship) {
              return InscribedTeams.query({
                phaseId: championship.phaseId,
                categoryId: championship.categoryId,
                sportId: championship.sportId
              }).$promise;
            },
            towns: function (Poblacions) {
              return Poblacions.query().$promise;
            }
          }
        });
  }
}());
